import { t } from '@lingui/macro';
import { Chip, Divider, Typography, alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CreditCard } from 'src/shared/icons/CreditCard';
import { Security } from 'src/shared/icons/Security';
import { User } from 'src/shared/icons/User';
import { makeStyles } from 'tss-react/mui';
import { useMutation } from 'react-query';
import { sharedAuthPostKeygen } from 'src/core/query/sharedKeygen';
import { LandingUrl } from 'src/core/domain/LandingUrls';
import { useConfirm } from 'src/shared/contexts/confirm/confirm.context';
import { USER_AUTH_TOKEN, USER_ROLE } from 'src/core/domain/auth/localStorageKeys';
import { Logout } from 'src/shared/icons/Logout';

type PropsType = {
  onClose: () => void;
  role: string | null;
  username: string | undefined;
};

const actions = [
  {
    icon: <User />,
    title: t`Personal info`,
    route: 'personal-info',
  },
  {
    icon: <CreditCard />,
    title: t`Bank accounts`,
    route: 'bank-accounts',
  },
  {
    icon: <Security />,
    title: t`Security`,
    route: 'security',
  },
];

export const ActionsPopUp = (props: PropsType) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { mutateAsync } = useMutation(sharedAuthPostKeygen('/api/v1/auth/user/logout'));

  const handleActionOnClick = (route: string) => () => {
    navigate(`/account?action=${route}`);
    props.onClose();
  };

  const handleLogout = () => {
    confirm({
      title: t`Log out of the account`,
      description: t`Are you sure you want to log out?`,
      confirmButtonText: t`Log out`,
    }).then(async () => {
      await mutateAsync({ params: {} });
      localStorage.removeItem(USER_AUTH_TOKEN);
      localStorage.removeItem(USER_ROLE);
      window.location.href = LandingUrl;
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Typography color={'white'} variant="body1" fontWeight={700}>
          {props.username}
        </Typography>
        <Chip
          size="small"
          label={props.role === 'Investor' ? t`Investor` : t`Broker`}
          classes={{ root: classes.chip }}
          variant="outlined"
        />
      </div>
      <div className={classes.notifList}>
        {actions?.map((action) => (
          <>
            <div className={classes.row} onClick={handleActionOnClick(action.route)}>
              <Typography variant="body1" color={'neutral.darkGrey'}>
                {action.icon}
              </Typography>
              <Typography variant="body2">{action.title}</Typography>
            </div>
            <Divider />
          </>
        ))}

        <div className={classes.row} onClick={handleLogout}>
          <Typography variant="body1" color={'neutral.darkGrey'}>
            <Logout />
          </Typography>
          <Typography variant="body2">{t`Logout`}</Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 300,
    boxShadow: `8px 8px 8px 8px ${alpha(theme.palette.neutral.dark, 0.5)}`,
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    background: theme.palette.neutral.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      width: 336,
    },
  },
  card: {
    width: '100%',
    maxWidth: 336,
    borderRadius: 16,
    padding: theme.spacing(3),
    background: 'linear-gradient(92deg, #001A70 0, #0B1226 100%)',
    boxShadow: `0px 24px 48px 0px rgba(16, 137, 255, 0.08)`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
  },
  row: {
    display: 'flex',
    flexGrow: 1,
    gap: theme.spacing(1),
    cursor: 'pointer',
    alignItems: 'center',
  },
  endHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  notifList: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
  },
  notif: {
    minWidth: 0,
    flexGrow: 1,
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    background: theme.palette.neutral.lightBlue,
    color: theme.palette.secondary.dark,
  },
}));
